import React, { useState, useEffect } from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import {
  Button,
  Container,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core"

const NotFoundPage = () => {
  const [waiting, setWaiting] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setWaiting(false)
    }, 4000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Layout>
      <Container>
        <Paper>
          <SEO title="404: Not found" />
          {waiting ? (
            <LinearProgress />
          ) : (
            <>
              <Typography variant="h1">Page Not Found</Typography>
              <Typography variant="body1">
                For some reason we cannot load this page.
              </Typography>
              <Button component={Link} to="/">
                Back to Home Page
              </Button>
            </>
          )}
        </Paper>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
